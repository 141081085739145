@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
body {
  background: #090926;
  color: #ffffff;
  font-family: "Abel", sans-serif !important;
  font-size: 18px;
}
a {
  text-decoration: none !important;
  color: #008ccc;
  transition: 0.5s;
}

a:hover {
  color: #00c06b;
}
.border-top-0 {
  border-top: 0 !important;
}
.green-bg {
  background: #00c06b !important;
  border: 0 !important;
}
.input-group-text {
  border: 0 !important;
  display: inline-flex;
  align-items: center;
  background: #008ccc;
  color: #ffffff;
  min-width: 60px;
  justify-content: center;
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  display: block;
  margin-bottom: 7px;
}
.fileUploadButton {
  position: relative;
  overflow: hidden;
}
.fileUploadButton input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile_dropdown .dropdown-item.active,
.profile_dropdown .dropdown-item:active,
.profile_dropdown .dropdown-item:focus,
.profile_dropdown .dropdown-item:hover {
  background-color: #007938 !important;
}
.dropdown-toggle::after {
  border-top-width: 0.4em !important;
  border-right-width: 0.4em !important;
  border-left-width: 0.4em !important;
  vertical-align: middle !important;
  color: #02a05a;
}
.dropdown-menu {
  background: #02a05a;
}
.dropdown-menu li a {
  color: #ffffff;
}
.dropdown-menu li a:hover {
  background: #007938 !important;
  color: #ffffff;
}
.form-control,
.form-select {
  box-shadow: none !important;
  border: 0 !important;
  outline: none !important;
  padding: 10px 0 10px 10px !important;
}
.input-group-text.select-input-group {
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.input-group-text.select-input-group .form-select {
  min-width: 80px;
}
.btn.btn-green-big {
  background: #00c06b;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  min-width: 280px;
  padding: 12px 15px;
  margin: 0 auto;
  display: block;
  border: 0 !important;
}
.btn.btn-green-big:hover {
  background: #008ccc !important;
}
.label-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.switch-group {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.switch-group label {
  margin-bottom: 0;
  margin-right: 10px;
}
label.form-check-label span {
  margin-left: 10px;
}
.customTable {
  color: #ffffff;
  border-color: #2d2d5b;
  margin-top: 15px;
  white-space: nowrap;
}
.customTable th {
  font-size: 16px;
  padding: 10px 15px;
  color: #d5d5d5;
}
.customTable td {
  font-size: 16px;
  padding: 10px 15px;
}
.yellowText {
  color: #ffb400 !important;
}
.greenText {
  color: #00c06b !important;
}
.blueText {
  color: #0091ff !important;
}
.redText {
  color: #ff3636 !important;
}
.flexLabel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.flexLabel label {
  margin-bottom: 0;
}
.flexTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterSelect {
  max-width: 140px;
}
/* Crediantial Styles */
.crediantial-layout {
  padding: 80px 0;
}
.crediantial-layout .navbar-brand {
  margin: 0;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.crediantial-form {
  background: #0d0d30;
  padding: 30px;
  border-radius: 20px;
}
.crediantial-form h2 {
  font-size: 34px;
}
.crediantial-form h6 {
  color: #bababa;
  font-size: 16px;
  margin-bottom: 20px;
}
.crediantila-bottom {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
}

.crediantial-form .nav-tabs {
  border-bottom: 0;
  margin: 30px 0 25px;
}
.crediantial-form .nav-tabs .nav-link {
  background: #090927;
  color: #008ccc;
  font-size: 20px;
  border-radius: 7px !important;
  border: 0 !important;
  font-weight: 600;
}
.crediantial-form .nav-tabs .nav-link + .nav-link {
  margin-left: 10px;
}
.crediantial-form .nav-tabs .nav-link:hover,
.crediantial-form .nav-tabs .nav-link.active {
  background: #00c06b;
  color: #ffffff;
}
/* User page Styles */
.page-content {
  margin-top: 180px;
  min-height: 80vh;
}
.userContent {
  background: #0d0d30;
  padding: 30px;
  border-radius: 20px;
}
.navbar.userTopMenu {
  background: #090926;
}
.form-card {
  border: 2px solid #2d2d5b;
  border-radius: 20px;
  padding: 0 30px 15px 30px;
  margin-top: 50px;
}
.form-card h3 {
  margin-top: -12px;
  font-size: 18px;
  margin-bottom: 20px;
}
.form-card h3 span {
  background: #0d0d30;
  padding: 0 15px;
}
.noteList {
  margin: 0;
  padding: 0 0 20px 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.noteList li {
  margin: 4px 0;
}
.captureFrame * {
  border-radius: 20px;
}
.userContent h2 {
  font-size: 28px;
  margin-bottom: 0;
}

/* Wallet page Styles */
.WalletBalance {
  border-top: 1px solid #2d2d5b;
  padding-top: 15px;
  margin-top: 18px;
}
.wbCard h6 {
  font-size: 18px;
  color: #bababa;
  margin: 0;
  padding: 0;
}
.wbCard h2 {
  font-size: 30px;
  color: #00c06b;
  font-weight: 600;
  margin: 5px 0 5px;
  padding: 0;
}
.wbCard h2 small {
  font-size: 16px;
  color: #ffffff;
}
.wbCard h4 {
  font-size: 18px;
  color: #008ccc;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.qrCodeBox {
  background-color: #ffffff;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}
.copyCodeBox {
  background: #fff;
  color: #000;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.copyCodeBox p {
  word-break: break-all;
  margin-bottom: 0;
}
.copyCodeBox a {
  min-width: 16px;
  margin-left: 15px;
}
.bankDetailBox ul {
  list-style: none;
  margin: 15px 0 20px;
  padding: 0;
}
.bankDetailBox ul li {
  margin: 0 0 10px 0;
  font-size: 16px;
}
.light_theme .bankDetailBox ul li {
  color: #222222;
}
.bankDetailBox ul li span.bdLabel {
  width: 166px;
  display: inline-block;
}
.bankDetailBox ul li span.bdDetails {
  font-weight: 600;
}
.withdrawDetailBox ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.withdrawDetailBox ul li {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.greenNote {
  color: #00c06b;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid #2d2d5b;
  padding-top: 15px;
  margin-top: 15px;
}
/* User page Top Navigation Styles */
.userTopMenu .nav-link {
  color: #ffffff;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #ffffff;
}
.userTopMenu .navbar-brand {
  color: #ffffff;
}
.topProfileDropdown {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navbar-toggler {
  color: #fff !important;
  background: #00c06b !important;
}
.navbar-toggler span {
  color: #fff !important;
}
/* Profile page side menu */
.profileSideMenu {
  background: #0d0d30;
  border-radius: 20px;
  padding: 15px;
  position: sticky;
  top: 115px;
}
.profileSideMenuTop {
  padding: 15px;
  border-bottom: 1px solid #2d2d5b;
}
.profileSideMenuTop p a {
  font-size: 16px;
  color: #bababa;
}
.kyc_verify {
  display: flex;
  align-items: center;
  padding: 8px 0 2px;
}
.kyc_verify span {
  color: #00c06b;
  font-size: 14px;
}
.profileSideMenuTop p small {
  font-size: 14px;
  color: #bababa;
}
.profileSideMenuBottom {
  padding: 15px;
}
.profileSideMenu .navbar-nav .nav-item {
  margin: 3px 0px;
}
.profileSideMenu .navbar-nav .nav-item a {
  display: flex;
  align-items: center;
}
.profileSideMenu .navbar-nav .nav-item a:hover {
  color: #00c06b;
}
.profileSideMenuBottom .nav-link img {
  max-width: 15px;
  margin-right: 15px;
}

/* Wallet page side menu */
.walletSideMenuBottom .nav-item {
  margin-bottom: 15px;
}
.walletSideMenuBottom .nav-item .nav-link {
  background: #0d0d30;
  padding: 15px 15px;
  border-radius: 15px;
  font-size: 20px;
  color: #ffffff;
  transition: 0.5s;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.walletSideMenuBottom .nav-item .nav-link.active,
.walletSideMenuBottom .nav-item .nav-link:hover {
  background: #008ccc;
}
.walletMenuIcon1 {
  background: url(../images/walletMenuIcon-1.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.walletMenuIcon2 {
  background: url(../images/walletMenuIcon-2.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.walletMenuIcon3 {
  background: url(../images/walletMenuIcon-3.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.walletMenuIcon4 {
  background: url(../images/walletMenuIcon-4.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
/* Dashboard page side menu form */
.dashboardContent {
  background: #0d0d30;
  padding: 0px;
  border-radius: 10px;
}
.dashboardContentTitleBar {
  background-color: #191d42;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dashboardContentTitleBar h3 {
  margin-bottom: 0;
  font-size: 18px;
}
.chartTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #2d2d5b;
  border-bottom: 1px solid #2d2d5b;
  padding: 15px 0;
  margin: 15px 0;
}
.chartTitle h4 {
  margin: 0;
}
.chartTitle .form-select {
  min-width: 130px;
}
.dashboardBalanceDetails h2 {
  font-size: 18px;
  color: #dddddd;
  font-weight: 600;
  line-height: 26px;
}
.dashboardBalanceDetails h2 span {
  color: #ffffff;
  font-size: 14px;
  margin-top: 7px;
  font-weight: 500;
}
.dashboardTwoColumn {
  margin-bottom: 25px;
}
.dashboardTwoColumn .userContent {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dashboardTabs {
  border-bottom: 0 !important;
  flex-wrap: nowrap;
}
.dashboardTabs li {
  max-width: 33%;
}
.dashboardTabs li button {
  border-radius: 0 !important;
  background: transparent !important;
  border-bottom-color: transparent !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom-width: 2px !important;
  color: #8b8b90 !important;
  white-space: nowrap;
}
.dashboardTabs li button.active {
  border-bottom-color: #008ccc !important;
  color: #ffffff !important;
}

.dashboardPairDetails .slick-arrow {
  position: absolute;
  top: -52px;
}
.dashboardPairDetails .slick-arrow.slick-prev {
  left: auto !important;
  right: 20px;
}
.dashboardPairDetails .slick-arrow.slick-next {
  left: auto !important;
  right: -10px;
}
.pairCard {
  padding: 0 8px;
}
.pairCard .pair_table_bodyrow {
  border: 1px solid #2d2d5b;
  border-radius: 5px;
  padding: 15px;
}
.pairCard .pair_table_bodyrow_bottom_left > div {
  margin-top: 5px;
}
/* Wallet page side menu form */
.walletForm {
  border-top: 1px solid #2d2d5b;
  padding-top: 15px;
  margin-top: 18px;
}

/* Footer style css */
.userFooter {
  background: #04041a;
  padding: 30px 0;
  margin-top: 30px;
}
.userFooter p {
  font-size: 16px;
}

/* Trade Page Css Starts */
.trade_wrapper {
  margin-top: 160px;
}
.trade_topbar {
  background: #0d0d30;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.trade_topbar p {
  color: #9c9c9c;
  font-size: 14px;
  margin-bottom: 0;
}
.trade_topbar h3 {
  color: #fff;
  font-size: 20px;
  margin: 5px 0;
}
.trade_topbar h6 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.trade_topbar > div {
  padding: 10px 0;
}
.trade_topbar > div:first-child {
  background: #151540;
  padding: 10px 30px;
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  min-height: 80px;
}
.trade_topbar > div:first-child img {
  width: 24px;
  margin-right: 5px;
}
.trade_topbar > div:last-child {
  padding: 10px 30px;
}
.trade_chart {
  border-radius: 10px;
  width: 100%;
}
.trade_chart img {
  width: 100%;
  height: 560px;
  object-fit: fill;
  object-position: center;
  border-radius: 10px;
}
div#tv_chart_container {
  height: 560px;
}
nav.navbar.userTopMenu.trade_menu {
  background: #05051f;
}
.trade_main {
  display: flex;
  align-items: flex-start;
}
.trade_main_left_top {
  display: flex;
  align-items: flex-start;
}
.trade_main_left {
  width: 80%;
}
.coin_details {
  margin-right: 10px;
  min-width: 25%;
}
.trade_card {
  border: none;
  background: transparent;
}
.trade_card .card-header {
  background: #191d42;
}
.trade_card .card-header h2 {
  font-size: 18px;
  margin: 0;
}
.trade_card .card-body {
  padding: 0;
  background: #0d0d30;
  border-radius: 0 0 10px 10px;
}
.trade_card ul.nav-tabs {
  background: #151540;
  border: none;
  padding-left: 8px;
}
.trade_card .nav-tabs .nav-link {
  border: none;
  border-radius: 0;
  color: #616588;
  font-size: 18px;
}
.trade_card .nav-tabs .nav-item.show .nav-link,
.trade_card .nav-tabs .nav-link.active,
.trade_card .nav-tabs .nav-link:focus,
.trade_card .nav-tabs .nav-link:hover {
  background: #0d0d30;
  border: none;
  color: #fff;
}
input.form-control.search_inp {
  border: 1px solid #23274c !important;
  background: transparent;
  color: #959596;
  font-size: 14px;
}
.search_inp_div {
  position: relative;
  padding: 0 10px;
  margin-top: 15px;
}
.search_inp_div .bi-search {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #8f8f91;
}
.pair_table_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 30px 10px 10px;
  border-bottom: 1px solid #23274c;
}
.pair_table_head_batch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 30px 10px 10px;
  border-bottom: 1px solid #23274c;
}
.pair_table_head_batch > div {
  font-size: 14px;
  color: #fff;
  position: relative;
}
.light_theme .pair_table_head_batch > div {
  color: #333333;
}
.pair_table_head > div {
  font-size: 14px;
  color: #fff;
  position: relative;
}
.pair_table_head > div::after,
.pair_table_head_batch > div::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -15px;
  background: url("../images/sort_icon.png") no-repeat;
  width: 9px;
  height: 12px;
}
.pair_table_body {
  padding: 10px;
}
.pair_table_bodyrow_top img {
  width: 26px;
  margin-right: 5px;
}
.pair_table_bodyrow_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pair_table_bodyrow_top > div:first-child {
  display: flex;
  align-items: center;
}
.pair_table_bodyrow_top > div:nth-child(2) {
  display: flex;
  align-items: center;
}
.pair_table_bodyrow_top > div:first-child h3 {
  font-size: 18px;
  color: #fff;
  margin: 0;
}
.pair_table_bodyrow_top > div:first-child h3 span {
  font-size: 14px;
  color: #9d9fc3;
}
.bg_green {
  background-color: #039c70 !important;
}
.bg_red {
  background-color: #d7615b !important;
}
.bg_blue {
  background-color: #cde9f6 !important;
  color: #3a6788;
}
.bg_yellow {
  background-color: #f8f4c3 !important;
  color: #785d20;
}
.trade_type {
  font-size: 12px;
  font-weight: 400;
  padding: 3px 5px;
  height: 18px;
}
.pair_table_bodyrow_top > div .bi {
  font-size: 15px;
  margin-left: 8px;
  color: #00c06b;
}
.per_change {
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
}
.pair_table_bodyrow_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
}
.pair_table_bodyrow_bottom label {
  font-size: 14px;
  color: #9d9fc3;
}
.pair_table_bodyrow_bottom_left > div label:nth-child(2),
.pair_table_bodyrow_bottom_right > div label:nth-child(2) {
  color: #fff;
}
.pair_table_bodyrow_bottom_left,
.pair_table_bodyrow_bottom_right {
  width: 45%;
}
.pair_table_bodyrow_bottom_left > div,
.pair_table_bodyrow_bottom_right > div {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
}
.pair_table_bodyrow {
  border-bottom: 1px solid #23274c;
  padding: 10px 15px 10px 8px;
  cursor: pointer;
  transition: 0.2s all;
}
.pair_table_bodyrow.active,
.pair_table_bodyrow:hover {
  background: #191d42;
}
.batch_settings {
  background: #191d42;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 5px 5px;
}
.batch_settings p {
  font-size: 14px;
  color: #9698bc;
  margin: 0;
  padding: 2px 10px;
  cursor: pointer;
}
.choose_pair img {
  width: 16px;
  margin-right: 5px;
}
.choose_pair {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.choose_pair p {
  margin: 0;
  font-size: 14px;
}
.rdt_TableCol_Sortable span {
  position: relative;
  text-indent: -999px;
  opacity: 1;
  transform: unset;
}
.rdt_TableCol_Sortable span::before {
  content: "";
  background: url("../images/sort_icon.png") no-repeat;
  width: 9px;
  height: 12px;
  position: absolute;
  right: -12px;
  top: 4px;
}
.trade_main_left_bottom {
  margin-top: 10px;
}
.trade_history_card ul.nav-tabs {
  background: transparent;
  padding: 0;
}
.trade_history_card .nav-tabs .nav-item.show .nav-link,
.trade_history_card .nav-tabs .nav-link.active,
.trade_history_card .nav-tabs .nav-link:focus,
.trade_history_card .nav-tabs .nav-link:hover {
  background: transparent;
  border-bottom: 2px solid #008ccc;
}
.trade_history_card .card-header {
  padding: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade_history_card .nav-tabs .nav-link {
  padding: 10px 0 10px;
  margin: 0 20px;
}
.primary_table .rdt_TableHeadRow,
.primary_table .rdt_Pagination {
  background-color: #0d0d30 !important;
  color: #959596 !important;
  font-size: 14px !important;
}
.primary_table .rdt_TableHeadRow {
  border-bottom: 1px solid #23274c !important;
}
.primary_table .rdt_Pagination {
  border-top: 1px solid #23274c !important;
  border-radius: 0 0 10px 10px;
}
.primary_table .rdt_TableRow {
  background-color: #0d0d30 !important;
  color: #fff !important;
  font-size: 14px !important;
  border-bottom: none !important;
}
.primary_table .rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%) !important;
  fill: rgb(255 255 255 / 18%) !important;
}
.primary_table .rdt_Pagination button {
  color: rgb(255 255 255 / 54%) !important;
  fill: rgb(255 255 255 / 54%) !important;
}
.light_theme .primary_table .rdt_Pagination button:disabled {
  color: rgb(0 0 0 / 18%) !important;
  fill: rgb(0 0 0 / 18%) !important;
}
.light_theme .primary_table .rdt_Pagination button {
  color: rgb(0 0 0 / 54%) !important;
  fill: rgb(0 0 0 / 54%) !important;
}
.trade_main_right {
  width: 20%;
  margin-left: 10px;
}
.trade_main_right .trade_card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade_main_right .trade_card .card-header p {
  color: #959596;
  font-size: 14px;
  margin: 0;
}
.card_body_padding {
  padding: 10px;
}
.trade_main_right .trade_card .form-group {
  margin-bottom: 8px;
}
.trade_main_right .trade_card .form-group label {
  margin-bottom: 2px;
}
.f_16 {
  font-size: 16px;
}
.trade_options_card {
  display: flex;
  flex-wrap: wrap;
  background: #0d0d30;
  margin-top: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.trade_options_card div {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.trade_options_card div h3 {
  font-size: 16px;
  margin: 10px 0 0;
}
.trade_options_card div:nth-child(1),
.trade_options_card div:nth-child(3) {
  border-right: 1px solid #23274c;
}
.trade_options_card div:nth-child(1),
.trade_options_card div:nth-child(2) {
  border-bottom: 1px solid #23274c;
}
.strategy_btn {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #008ccc;
  border: 2px solid #008ccc;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  transition: 0.2s all;
}
.strategy_btn:hover {
  background: transparent;
}
.strategy_settings_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 1px solid #23274c;
}
.strategy_settings_div div {
  display: flex;
  align-items: center;
}
.strategy_settings_div div img {
  margin-right: 15px;
}
.strategy_settings_div h5 {
  font-size: 16px;
  margin: 0;
  max-width: 65%;
}
.strategy_settings_div h6 {
  font-size: 16px;
  color: #9698bc;
  margin: 0;
}
.strategy_settings_panel {
  background: #0d0d30;
  border-radius: 10px;
}
.strategy_settings_panel .strategy_settings_div:last-child {
  border-bottom: 0;
}
.primary_btn {
  background-color: #00c06b;
  border: 2px solid #00c06b;
  margin: 10px 0 0;
  width: 100%;
  border-radius: 10px;
  color: #fff;
  padding: 8px 20px;
}
.primary_btn:hover {
  background-color: transparent;
}

.danger_alert {
  background-color: #a32610;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
}

.danger_alert p {
  margin: 0;
  font-size: 16px;
  color: #ffffff;
  margin-left: 10px;
  line-height: 18px;
}

.danger_alert.alert-dismissible .btn-close {
  background: url("../images/alert_close_icon.png") center/1em auto no-repeat;
  opacity: 1;
  padding: 12px 30px 14px 10px;
}

.info_alert {
  background-color: #e6effa;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
}

.info_alert p {
  margin: 0;
  font-size: 16px;
  color: #05051f;
  margin-left: 10px;
  line-height: 18px;
}
.info_alert.alert-dismissible .btn-close {
  background: url("../images/alert_close_icon.png") center/1em auto no-repeat;
  opacity: 1;
  padding: 12px 30px 14px 10px;
}
.saved_strategies_panel h2 {
  font-size: 20px;
  margin: 20px 0 0;
}
.saved_strategies_panel button {
  margin: 10px 0;
  background: #191d42;
  border: 1px solid #23274c;
  padding: 10px 10px 10px 20px;
  border-radius: 10px;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 20px;
  text-align: left;
  transition: 0.2s all;
}
.saved_strategies_panel button:hover {
  background: #23274c;
  border: 1px solid #191d42;
}
.strategy_settings_close {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}
.new_strategy_settings_panel > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #23274c;
}
.new_strategy_settings_panel > div:nth-child(1),
.new_strategy_settings_panel > div:nth-child(2) {
  display: block;
}
.new_strategy_settings_panel > div:last-child {
  border-bottom: 0;
}
.new_strategy_settings_panel > div a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new_strategy_inp {
  width: 60px;
  height: 34px;
  padding: 5px 10px 5px 10px !important;
}
.new_strategy_settings_panel h6 {
  color: #9698bc;
  margin: 0;
}
.edit_indicators {
  color: #008ccc;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}
.edit_indicators .bi {
  font-size: 14px;
}
.new_strategy_settings_panel label {
  font-size: 16px;
}
.new_strategy_settings_panel label.label_large {
  font-size: 20px;
}
#custom_amt_setup i {
  color: #008ccc;
}
.modal-backdrop.show {
  opacity: 0.78;
}
.primary_modal .modal-content {
  background-color: #0d0d30;
  border-radius: 15px;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #23274c;
}
.primary_modal .modal-title {
  font-size: 24px;
}
.primary_modal .btn-close {
  background: url("../images/modal_close_btn.png") center/24px auto no-repeat;
  opacity: 1;
  width: 24px;
  height: 24px;
  box-shadow: none;
}
nav.trade_menu ul.topProfileDropdown {
  display: flex;
  align-items: center;
}
nav.trade_menu ul.topProfileDropdown li {
  padding: 0 15px;
}
nav.trade_menu ul.topProfileDropdown li a {
  color: #fff;
}
.menu_round_btn {
  width: 35px;
  height: 35px;
  background: #151540;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.notification_link {
  position: relative;
}
.notification_link sup {
  position: absolute;
  right: -3px;
  top: -8px;
  background: #e90c15;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
}
.theme_button.menu_round_btn {
  background: #008ccc;
  cursor: pointer;
}
.new_bot_btn {
  background-color: #008ccc;
  border: 2px solid #008ccc;
  border-radius: 3px;
  color: #fff;
  padding: 1px 20px;
  text-transform: uppercase;
  transition: 0.2s all;
}
.new_bot_btn:hover {
  background-color: transparent;
}
.profile_dropdown {
  margin-top: 15px !important;
  padding: 0;
}
.profile_dropdown::after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #02a05a;
}
.profile_dropdown li {
  padding: 0 !important;
  border-bottom: 1px solid #03aa50;
}
.profile_dropdown li a {
  padding: 8px 15px;
  font-size: 18px;
  display: flex !important;
  align-items: center;
}
.profile_dropdown li:last-child {
  border-bottom: 0;
}
.profile_dropdown li a img,
.profile_dropdown li label img {
  margin-right: 10px;
  max-width: 15px;
  filter: brightness(0) invert(1);
}
.trade_sidemenu {
  background-color: transparent;
  position: relative;
  top: unset;
  padding: 0;
  flex-grow: unset;
}
.topProfileDropdown_mobile {
  display: none !important;
}
.demo_list label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #fff !important;
  cursor: pointer;
  padding: 8px 15px;
}
.demo_list label:hover {
  background: #007938 !important;
}
.d_d_none {
  display: none !important;
}
.trade_sidemenu .navbar-nav .nav-item a:hover {
  color: #fff;
}
.new_bot_panel > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  background: rgb(0, 96, 170);
  background: linear-gradient(
    100deg,
    rgba(0, 96, 170, 1) 0%,
    rgba(0, 152, 70, 1) 100%
  );
  border: 1px solid #008ccc;
  padding: 30px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
}
.new_bot_panel > a .new_bot_panel_content {
  display: flex;
  align-items: center;
}
.new_bot_panel_content h4 {
  font-size: 24px;
  margin: 0 0 0 15px;
  color: #ffffff;
}
.icon_start {
  opacity: 0.45;
}
.new_bot_panel > a:hover .icon_start {
  opacity: 1;
}
#mobileMenuDrop a {
  background: #0d0d30;
  display: flex;
  padding: 15px;
  border-radius: 15px;
  font-size: 20px;
  color: #fff;
  transition: 0.5s;
  align-items: center;
  justify-content: space-between;
}
#mobileMenuDrop a:hover,
#mobileMenuDrop a.active {
  background: #008ccc;
}
#mobileMenuDrop a.active::after {
  content: "\F119";
  font-family: bootstrap-icons !important;
}
#mobileMenuDrop ul {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
}
#mobileMenuDrop ul li {
  margin-top: 15px;
}
#mobileMenuDrop ul li a {
  background: #0d0d30;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 15px;
  font-size: 20px;
  color: #fff;
}
#mobileMenuDrop ul li a:hover {
  background: #008ccc;
}
.flexIconLeft {
  display: inline-flex;
  align-items: center;
}
button.primary_btn.primary_blue_btn {
  background-color: #008ccc;
  border: 2px solid #008ccc;
}
button.primary_btn.primary_blue_btn:hover {
  background-color: transparent;
}
#custom_amt_setup,
#custom_amt_setup label {
  cursor: pointer;
}
.inp_grp_width {
  width: 52px;
  justify-content: center;
}
.custom_amt_setup_div label {
  font-size: 16px;
}
.custom_amt_setup_div .row {
  padding: 5px 0;
}
.custom_amt_setup_back {
  cursor: pointer;
}
.primary_accordian .accordion-item {
  margin: 10px 0;
  background-color: #151540;
  border: 1px solid #1e2247;
  border-radius: 5px;
}
.primary_accordian .accordion-button {
  background-color: transparent;
  border: none;
  border-radius: 0 !important;
  color: #fff;
  font-size: 16px;
  box-shadow: none;
  padding: 10px;
}
.indicators_panel div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #191b43;
}
.indicators_panel div:nth-child(1) {
  border-top: 1px solid #191b43;
}
.indicators_panel div label {
  font-size: 16px;
  color: #9698bc;
}
.indicators_inp {
  width: 156px;
  padding: 3px 10px !important;
}
.accordion-button::after {
  content: "\F4FE";
  font-family: bootstrap-icons !important;
  border-radius: 50%;
  background: rgb(0, 96, 170);
  background: linear-gradient(
    100deg,
    rgba(0, 96, 170, 1) 0%,
    rgba(0, 152, 70, 1) 100%
  ) !important;
  border: 1px solid #120b3b;
  color: #151540;
  width: 25px;
  height: 25px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-button:not(.collapsed)::after {
  content: "\F229";
  transform: unset;
  font-size: 14px;
  line-height: 32px;
}
.batch_settings_modal_panel {
  display: flex;
}
#batch_setting_modal .modal-dialog {
  min-width: 800px;
}
.batch_settings_modal_panel .coin_details {
  border: 1px solid #23274c;
  border-radius: 6px;
  min-width: 42%;
  margin-right: 20px;
}
.batch_settings_modal_panel .coin_details .form-check-input[type="checkbox"] {
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 12px;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #23274c;
  width: 20px;
}
.batch_settings_modal_panel .coin_details .pair_table_bodyrow {
  width: 100%;
  padding-right: 15px;
}
.batch_settings_modal_right {
  width: 58%;
}
.operate_settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operate_settings h3,
.operate_settings label {
  font-size: 18px;
  margin: 0;
}
.operate_settings .form-check-input[type="checkbox"] {
  border-radius: 50%;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #23274c;
}
.batch_settings_modal_panel
  .coin_details
  .form-check-input:checked[type="checkbox"],
.operate_settings .form-check-input:checked[type="checkbox"] {
  background-color: #2196f3;
}
.batch_settings_btn_grp > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.batch_settings_btn_grp button {
  background: #191d42;
  border: 1px solid #191d42;
  padding: 6px 10px 6px 15px;
  border-radius: 10px;
  display: block;
  width: 50%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  transition: 0.2s all;
}
.batch_settings_btn_grp > div button:nth-child(2) {
  margin-left: 20px;
}
.batch_settings_btn_grp button img {
  margin-right: 10px;
}
.batch_settings_btn_grp {
  margin-top: 15px;
}
/* Trade Page Css Ends */

.message_icon {
  background: url(../images/message_icon.png) no-repeat scroll 0 0;
  display: inline-block;
  width: 23px;
  height: 18px;
  margin-right: 15px;
}
.notification_panel {
  margin-top: 10px;
}
.notification_panel h4 {
  font-size: 16px;
}
.notification_panel h5 {
  color: #008ccc;
  font-size: 16px;
  margin: 0;
}
.notification_panel div {
  border-bottom: 1px solid #2d2d5b;
  padding: 15px 0;
}
.notification_panel div:nth-child(1) {
  border-top: 1px solid #2d2d5b;
}
.api_binding_tab {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.api_binding_tab .nav-item .nav-link {
  background: #0b0b2a;
  border: 1px solid #2d2d5b;
  min-height: 250px;
}
.api_binding_tab .nav-item .nav-link.active {
  background: linear-gradient(
    100deg,
    rgba(0, 96, 170, 1) 0%,
    rgba(0, 152, 70, 1) 100%
  );
}
.api_binding_tab .nav-item .nav-link.active img {
  filter: brightness(0) invert(1);
}
.api_binding_card {
  background: #0b0b2a;
  border-radius: 10px;
  padding: 30px;
}
.api_binding_card_title {
  border-bottom: 1px solid #2d2d5b;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.api_binding_card_title h3 {
  font-size: 28px;
  margin: 0;
}
.api_binding_card_title h3 span {
  font-size: 16px;
}
.api_binding_card_title a {
  font-size: 16px;
}
.api_binding_card_content ol li {
  font-size: 16px;
}
.profileSideMenuBottom a.nav-link.active {
  color: #00c06b;
}
.edit_btn {
  background: #2d2d5b;
  border: 1px solid #2d2d5b;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 4px 12px;
}
.edit_btn i {
  font-size: 12px;
  margin-right: 3px;
}
.edit_btn:hover {
  background: #00c06b;
  border-color: #00c06b;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.panic_btn {
  background: #e90c15;
  border: 2px solid #e90c15;
  border-radius: 3px;
  color: #fff;
  padding: 1px 20px;
  text-transform: uppercase;
  transition: 0.2s all;
  position: relative;
}
.panic_btn img {
  position: absolute;
  left: -20px;
  top: -2px;
  filter: unset !important;
}
.member_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #191d42;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}
.member_box > div:nth-child(1) {
  display: flex;
  align-items: center;
}
.member_box > div:nth-child(1) img {
  margin-right: 8px;
}
.member_box > div:nth-child(1) h4 {
  font-size: 18px;
  margin: 0;
}
.member_box p {
  font-size: 14px;
  color: #9f9f9f;
}
.member_box h5 {
  font-size: 16px;
  margin: 0;
}
.renew_btn {
  background: #d8625b;
  border: 1px solid #d8625b;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  padding: 1px 20px;
}
.renew_btn:hover {
  background: transparent;
  color: #d8625b;
  border: 1px solid #d8625b;
}
.flexTitle .dashboardTabs {
  width: 70%;
}
.contact_sec p,
.contact_sec p a {
  color: #fff;
  font-size: 18px;
}
.contact_sec p span {
  color: #9c9c9c;
}
.tfa_status {
  background-color: #191d42;
  border-radius: 5px;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.tfa_status p {
  font-size: 18px;
  color: #bababa;
}
.tfa_status p span {
  margin-left: 5px;
}
.notes_section h5 {
  color: #bababa;
  font-size: 18px;
}
.notes_section p {
  font-size: 18px;
}
.notes_section p a {
  color: #00c06b;
}
.notes_section p a:hover {
  text-decoration: underline !important;
}
.notes_section ul {
  list-style: none;
}
.notes_section ul li {
  position: relative;
}
.notes_section ul li::before {
  content: "";
  background-color: #b9b9be;
  width: 5px;
  height: 2px;
  position: absolute;
  left: -20px;
  top: 14px;
}
.video_tutorials_panel {
  margin: 30px 0;
}
.video_tutorials_panel h3 {
  font-size: 18px;
  background: #008ccc;
  margin: -8px 0 0;
  padding: 10px 15px;
  border-radius: 0 0 12px 12px;
}
.video_tutorials_panel iframe {
  border-radius: 12px 12px 0 0;
}
.referral_panel {
  text-align: center;
  border: 2px solid #2d2d5b;
  border-radius: 20px;
  padding: 50px 40px;
  margin: 30px 0;
}
.referral_panel .input-group {
  max-width: 50%;
  margin: 0 auto;
}
.referral_panel .form-group {
  margin-top: 30px;
}
.referral_panel h3 {
  font-size: 36px;
  margin: 15px 0 30px;
}
.referral_panel h4 {
  font-size: 18px;
  color: #6d77ff;
  display: inline-block;
  margin: 0 15px 0 0;
}
.trade_card .card-header h2 img {
  width: 32px;
  margin-right: 5px;
}
.primary_modal.share_modal .modal-content {
  background: #0d0d30 url("../images/share_modal_bg.png") no-repeat;
  background-size: cover;
}
.share_modal .modal-body .share_modal_body_top {
  display: flex;
  align-items: center;
}
#share_modal .trade_type {
  font-size: 18px;
  height: auto;
  padding: 6px 12px;
  margin-right: 15px;
}
.share_trade_details_panel > div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #23274c;
  padding: 15px 0;
}
.share_trade_details_panel > div:last-child {
  border-bottom: none;
}
.share_trade_details_panel > div h3 {
  font-size: 32px;
  margin-bottom: 0;
  margin-right: 15px;
}
.share_trade_details_panel > div h4 {
  font-size: 18px;
}
.share_trade_details_panel > div h4 span {
  display: block;
}
.share_trade_details_panel > div h5 label,
.share_trade_details_panel > div label {
  margin: 0;
  color: #9d9fc3;
  font-size: 16px;
}
.share_trade_details_panel > div h5 span {
  font-size: 16px;
}
.share_trade_details_panel > div h2 {
  font-size: 24px;
}
.share_trade_details_panel > div h5 label {
  width: 140px;
}
.share_modal .modal-body .share_modal_body_left {
  width: 50%;
}
.share_modal .modal-body .share_modal_body_right {
  width: 50%;
  text-align: center;
}
.share_modal .modal-dialog {
  max-width: 600px;
}
.share_modal_body_bottom {
  border-top: 1px solid #23274c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 10px;
}
.social_share_list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}
.social_share_list li {
  margin-right: 20px;
}
.revenue_flex {
  display: flex;
  justify-content: end;
  background: #189846;
  padding: 12px 15px;
  border-radius: 5px;
}
.revenue_flex h4 {
  font-size: 18px;
  margin: 0;
}
.revenue_flex h4:nth-child(1) {
  margin-right: 50px;
}
img.share_icon {
  margin: -3px 0 0 2px;
}
.react-tel-input .flag-dropdown {
  border-radius: 5px 0 0 5px !important;
  z-index: 10 !important;
  background-color: #fff !important;
}
.react-tel-input .form-control {
  padding: 10px 0 10px 50px !important;
  height: unset !important;
  border-radius: 5px !important;
}
.auth_otp_btn button.new_bot_btn {
  padding: 5px;
  text-transform: capitalize;
  cursor: pointer;
}
.auth_otp_btn button.new_bot_btn:hover {
  background-color: #008ccc;
}
.auth_otp_btn {
  position: absolute;
  top: -49px;
  right: 2px;
  z-index: 10;
}
.react-tel-input .country-list .search-box {
  padding: 8px 8px !important;
  width: 85% !important;
  text-transform: capitalize;
}
.react-tel-input .country-list .country-name {
  color: #333;
}
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: rgb(0 152 70 / 20%) !important;
}
.invite_btn {
  background: transparent;
  border: 0;
}
.track_vertical {
  background-color: #1d2146;
}
.thumb_vertical {
  background-color: #0d0d30;
}
h2.kyc_success {
  text-align: center;
}

/* ID Card Css */
.id_wrapper {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
}
.id_card {
  background: #000000 url("../images/idcard_bg.png") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  margin: 20px;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: #fff;
  padding: 0 20px 0;
}
img.logo {
  display: block;
  text-align: center;
  margin: 15px auto;
}
.id_img {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.id_img img {
  width: 220px;
  border-radius: 30px;
  border: 5px solid #00c06b;
  height: 250px;
  object-fit: cover;
  object-position: top;
}
.id_card h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
}
.user_info {
  margin: 10px 0;
}
.user_info h3 {
  font-size: 24px;
  margin: 0;
}
.user_info h4 {
  font-size: 18px;
  margin: 0 0 5px;
}
.user_details {
  margin: 0px auto 40px;
  padding-bottom: 20px;
  position: relative;
}
.user_details::after {
  content: "";
  background-color: #fff;
  width: 56%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.user_details h5 {
  color: #e8e8e8;
  font-weight: 500;
  margin: 0 0 4px;
  font-size: 18px;
}
.shape_bottom {
  margin-bottom: -5px;
}
.print_btn button {
  background: #00c06b;
  color: #fff;
  border: 1px solid #00c06b;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 8px;
  margin-top: 20px;
}
@media print {
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .id_card {
    background: #000000 url("../images/idcard_bg.png") no-repeat !important;
    print-color-adjust: exact;
    transform: scale(0.65);
  }
  .print_btn,
  nav.navbar.userTopMenu.trade_menu,
  .userFooter {
    display: none;
  }
  .id_img img {
    width: 220px;
    height: 250px !important;
    min-height: 250px !important;
    object-fit: cover;
    object-position: top;
  }
  .page-content {
    margin-top: 0;
    min-height: 100%;
  }
}
.input-group-text.inp_grp_width a {
  color: #fff;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiInput-underline:after {
  display: none !important;
}
.profileSideMenuTop_eye {
  display: flex;
  align-items: center;
}
.profileSideMenuTop .profileSideMenuTop_eye i {
  margin-left: 15px;
}
.rdt_Table > div {
  color: #fff !important;
  background-color: #0d0d30 !important;
}
.chart_mobile_head {
  display: none;
}
/* .loader {
  border: 14px solid #f3f3f3;
  border-top: 14px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
} */
.loader {
  margin: 0 auto;
  background: url("../images/loadergain.gif");
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: 100%;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.profile_email {
  display: flex;
  align-items: center;
}
button.primary_btn.btn-danger {
  background: #dc3545;
  border-color: #dc3545;
}
button.primary_btn.btn-danger:hover {
  background-color: transparent;
}
#share_page_modal {
  width: 50%;
  margin: 50px auto;
}
.share_download_pdf {
  visibility: hidden;
  max-height: 0;
  position: absolute;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  z-index: -999;
}
.custom_tooltip {
  background-color: #008ccc !important;
}
.userTopMenu .navbar-brand img {
  width: 150px;
  margin-left: 15px;
}
.logo_beforeauth .navbar-brand img {
  width: 200px;
}

/* Light Theme Css Starts */
body.light_theme {
  background: #e5e5e6;
}
.light_theme .crediantial-form {
  background: #fff;
}
.light_theme .crediantial-form h2 {
  color: #008ccc;
}
.light_theme .crediantial-form h6,
.light_theme .form-group label,
.light_theme .crediantila-bottom {
  color: #333;
}
.light_theme .form-control,
.light_theme .form-select,
.light_theme .input-group-text.select-input-group,
.light_theme .react-tel-input .form-control,
.light_theme .react-tel-input .flag-dropdown,
.light_theme .react-tel-input .flag-dropdown.open,
.light_theme .react-tel-input .flag-dropdown.open .selected-flag,
.light_theme .react-tel-input .selected-flag:hover,
.light_theme .react-tel-input .selected-flag:focus {
  background-color: #e5e5e6 !important;
}
.light_theme .crediantial-form .nav-tabs .nav-link {
  background: #e5e5e6;
  color: #7b7b7b;
}
.light_theme .crediantial-form .nav-tabs .nav-link:hover,
.light_theme .crediantial-form .nav-tabs .nav-link.active {
  background: #00c06b;
  color: #ffffff;
}
.light_theme .profileSideMenu,
.light_theme .userContent,
.light_theme nav.navbar.userTopMenu.trade_menu,
.light_theme .dashboardContent,
.light_theme .trade_topbar,
.light_theme .trade_card .card-body,
.light_theme .trade_options_card,
.light_theme .strategy_settings_panel {
  background: #fff;
}
.light_theme .profileSideMenu .navbar-nav .nav-item a,
.light_theme nav.trade_menu ul.topProfileDropdown li a {
  color: #333;
}
.light_theme .profileSideMenuBottom a.nav-link.active {
  color: #00c06b;
}
.light_theme button.navbar-toggler {
  border: 0;
}
.light_theme img.share_icon {
  filter: invert(1);
}
.light_theme .hamburger .bar {
  background-color: #333;
}
.light_theme .profileSideMenuTop h3,
.light_theme .userContent h2,
.light_theme .pair_table_bodyrow_top > div:first-child h3,
.light_theme .chartTitle h4,
.light_theme .dashboardContentTitleBar h3,
.light_theme .dashboardTabs li button.active,
.light_theme .trade_topbar h3 {
  color: #111 !important;
}
.light_theme .profileSideMenuTop p a,
.light_theme .profileSideMenuTop p small {
  color: #404040;
}
.light_theme .dashboardContentTitleBar,
.light_theme .trade_card .card-header,
.light_theme .trade_topbar > div:first-child {
  background-color: #f3f3f3;
}
.light_theme .primary_table .rdt_TableHeadRow,
.light_theme .primary_table .rdt_Pagination,
.light_theme .primary_table .rdt_TableRow {
  background-color: #fff !important;
  color: #222 !important;
}
.light_theme .greenText {
  color: #00c06b !important;
}
.light_theme .trade_topbar h3.greenText {
  color: #00c06b !important;
}
.light_theme .trade_topbar h3.redText {
  color: #ff3636 !important;
}
.light_theme .pair_table_bodyrow_bottom_left > div label:nth-child(2),
.light_theme .pair_table_bodyrow_bottom_right > div label:nth-child(2),
.light_theme label {
  color: #333;
}
.light_theme .rdt_Table > div {
  color: #333 !important;
  background-color: #fff !important;
}
.light_theme .redText {
  color: #ff3636 !important;
}
.light_theme .pair_table_bodyrow_bottom label,
.light_theme .pair_table_bodyrow_top > div:first-child h3 span {
  color: #525475;
}
.light_theme .pair_table_bodyrow.active,
.light_theme .pair_table_bodyrow:hover {
  background: rgb(229 229 230 / 50%);
}
.light_theme .chartTitle .form-select {
  background-color: #e5e5e6;
}
.light_theme .chartTitle {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .primary_table .rdt_TableHeadRow,
.light_theme .profileSideMenuTop {
  border-bottom: 1px solid #e7e7e7 !important;
}
.light_theme .primary_table .rdt_Pagination {
  border-top: 1px solid #e7e7e7 !important;
}
.light_theme .userFooter {
  background: #ffffff;
}
.light_theme .userFooter p {
  color: #111;
}
.light_theme .slick-prev:before,
.light_theme .slick-next:before {
  color: #111;
}
.light_theme .pairCard .pair_table_bodyrow {
  border: 1px solid #e7e7e7;
}
.light_theme .dashboardBalanceDetails h2 {
  color: #5d5d5d;
}
.light_theme .theme_button.menu_round_btn,
.light_theme .menu_round_btn {
  background: #e5e5e6;
}
.light_theme .new_bot_btn:hover {
  color: #008ccc;
}
.light_theme .profile_dropdown li a img {
  filter: brightness(0) invert(1) contrast(0);
}
.light_theme .dropdown-menu {
  background: #f3f3f3;
  border-color: #f2f2f2;
}
.light_theme .profile_dropdown li {
  border-bottom: 1px solid #e5e5e6;
}
.light_theme .profile_dropdown::after {
  border-bottom: 15px solid #f3f3f3;
}
.light_theme .profile_dropdown .dropdown-item.active,
.light_theme .profile_dropdown .dropdown-item:active,
.light_theme .profile_dropdown .dropdown-item:focus,
.light_theme .profile_dropdown .dropdown-item:hover {
  background-color: #d6d6d6 !important;
}
.light_theme .form-card {
  border: 2px solid #e7e7e7;
}
.light_theme .form-card h3 span {
  background: #fff;
  color: #111;
}
.light_theme .noteList li {
  color: #5d5d5d;
}
.light_theme .primary_btn:hover {
  color: #00c06b;
}
.light_theme button.primary_btn.btn-danger:hover {
  color: #dc3545;
}
.light_theme .member_box {
  background-color: #e5e5e6;
}
.light_theme .member_box > div:nth-child(1) h4,
.light_theme .member_box p {
  color: #111;
}
.light_theme .member_box h5 {
  color: #555;
}
.light_theme .dashboardPairDetails p {
  color: #333;
}
.light_theme .contact_sec a,
.light_theme .contact_sec p {
  color: #333;
}
.light_theme .contact_sec p span {
  color: #7f7f7f;
}
.light_theme .revenue_flex,
.light_theme .tfa_status {
  background: #e5e5e6;
}
.light_theme .revenue_flex h4,
.light_theme .notification_panel h4,
.light_theme .api_binding_card_title h3,
.light_theme .referral_panel h3,
.light_theme .trade_card .card-header h2 {
  color: #111;
}
.light_theme .tfa_status p,
.light_theme .notes_section h5,
.light_theme .notes_section p,
.light_theme .notes_section ul li,
.light_theme .api_binding_card_content ol li,
.light_theme .api_binding_card_content p {
  color: #333;
}
.light_theme .walletSideMenuBottom .nav-item .nav-link {
  background: #d6d6d6;
  color: #111;
}
.light_theme .message_icon {
  filter: brightness(0.45);
}
.light_theme .walletSideMenuBottom .nav-item .nav-link.active,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover {
  background: #008ccc;
  color: #fff;
}
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .message_icon,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .message_icon {
  filter: unset;
}
.light_theme .notification_panel div:nth-child(1),
.light_theme .WalletBalance,
.light_theme .walletForm,
.light_theme .greenNote {
  border-top: 1px solid #e7e7e7;
}
.light_theme .notification_panel div {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .api_binding_tab .nav-item .nav-link {
  background: #f3f3f3;
  border: 1px solid #e7e7e7;
}
.light_theme .api_binding_tab .nav-item .nav-link.active {
  background: linear-gradient(
    100deg,
    rgba(0, 96, 170, 1) 0%,
    rgba(0, 152, 70, 1) 100%
  );
}
.light_theme .api_binding_card {
  background: #f3f3f3;
}
.light_theme .api_binding_card_title {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .referral_panel {
  border: 2px solid #e7e7e7;
}
.light_theme .invite_btn img {
  filter: brightness(0);
}
.light_theme .primary_modal .modal-content {
  background: #fff;
}
.light_theme .primary_modal .modal-header {
  border-bottom: 1px solid #e7e7e7;
  color: #111;
}
.light_theme .primary_modal .btn-close {
  filter: brightness(0.5);
}
.light_theme .trade_topbar p {
  color: #6f6f6f;
}
.light_theme .trade_card .card-body,
.light_theme .trade_options_card div h3,
.light_theme .strategy_settings_div h5 {
  color: #333;
}
.light_theme .strategy_settings_div h6 {
  color: #40414f;
}
.light_theme .trade_card ul.nav-tabs,
.light_theme .batch_settings {
  background: #f3f3f3;
}
.light_theme .trade_card .nav-tabs .nav-item.show .nav-link,
.light_theme .trade_card .nav-tabs .nav-link.active,
.light_theme .trade_card .nav-tabs .nav-link:focus,
.light_theme .trade_card .nav-tabs .nav-link:hover {
  background: #008ccc;
}
.light_theme input.form-control.search_inp {
  border: 1px solid #e7e7e7 !important;
  color: #333;
}
.light_theme .pair_table_head > div {
  color: #333;
}
.light_theme .pair_table_head,
.light_theme .pair_table_bodyrow {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .trade_history_card .nav-tabs .nav-item.show .nav-link,
.light_theme .trade_history_card .nav-tabs .nav-link.active,
.light_theme .trade_history_card .nav-tabs .nav-link:focus,
.light_theme .trade_history_card .nav-tabs .nav-link:hover {
  background: transparent;
  border-bottom: 2px solid #008ccc;
}
.light_theme .trade_history_card .nav-tabs .nav-link {
  color: #111;
}
.light_theme .batch_settings p {
  color: #111;
}
.light_theme .trade_options_card div:nth-child(1),
.light_theme .trade_options_card div:nth-child(3) {
  border-right: 1px solid #e7e7e7;
}
.light_theme .trade_options_card div:nth-child(1),
.light_theme .trade_options_card div:nth-child(2) {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .strategy_settings_div {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme .track_vertical {
  background-color: #e1e1e1;
}
.light_theme .thumb_vertical {
  background-color: #9d9d9d;
}
.light_theme .batch_settings_btn_grp button {
  background: #e5e5e6;
  border: 1px solid #e7e7e7;
  color: #333;
}
.light_theme .batch_settings_modal_panel .coin_details {
  border: 1px solid #e7e7e7;
}
.light_theme .operate_settings h3,
.light_theme .operate_settings label {
  color: #333;
}
.light_theme .strategy_btn:hover {
  background: transparent;
  color: #008ccc;
}
.light_theme .new_strategy_settings_panel > div {
  border-bottom: 1px solid #e7e7e7;
}
.light_theme button.primary_btn.primary_blue_btn:hover {
  color: #008ccc;
}
.light_theme .walletMenuIcon1,
.light_theme .walletMenuIcon2,
.light_theme .walletMenuIcon3,
.light_theme .walletMenuIcon4 {
  filter: brightness(0.45);
}
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon1,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon1,
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon2,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon2,
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon3,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon3,
.light_theme .walletSideMenuBottom .nav-item .nav-link.active .walletMenuIcon4,
.light_theme .walletSideMenuBottom .nav-item .nav-link:hover .walletMenuIcon4 {
  filter: unset;
}
.light_theme .customTable {
  border-color: #e7e7e7;
}
.light_theme .customTable td {
  color: #222222;
}
.light_theme .wbCard h6,
.light_theme .customTable th {
  color: #1f1f1f;
}
.light_theme .customTable .edit_btn i {
  color: #008ccc;
}
.light_theme .customTable .edit_btn:hover i {
  color: #222222;
}
.light_theme .withdrawDetailBox ul li {
  color: #333;
}
.light_theme h2.kyc_success {
  color: #00c06b !important;
}
.light_theme .modal-body {
  color: #333 !important;
}
/* Light Theme Css Ends */

/* Media Queries */
@media (min-width: 992px) {
  .profileSideMenu.navbar-collapse {
    display: block;
    flex-basis: auto;
  }
  .walletSideMenu.navbar-collapse {
    display: block;
    flex-basis: auto;
  }
}
@media (max-width: 1366px) {
  .pair_table_bodyrow {
    padding: 10px 8px 10px 0px;
  }
  .batch_settings_modal_panel .coin_details .pair_table_bodyrow {
    padding-right: 30px;
  }
}
@media (max-width: 1199px) {
  .trade_main {
    flex-wrap: wrap;
  }
  .trade_main_left {
    width: 100%;
  }
  .trade_main_right {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  .trade_topbar {
    flex-wrap: wrap;
  }
  .trade_topbar > div {
    width: 30%;
    justify-content: flex-start;
    padding: 5px 30px;
  }
  .trade_topbar > div:first-child {
    background: transparent;
    border-radius: 0;
    padding: 5px 30px;
  }
  .trade_topbar > div:last-child {
    padding: 5px 30px;
  }
  .coin_details {
    min-width: 30%;
  }
  .api_binding_tab .nav-item .nav-link {
    min-height: 180px;
    padding: 40px;
  }
  #share_page_modal {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .topProfileDropdown {
    display: none;
  }
  .navbar-toggler {
    box-shadow: none !important;
    outline: 0 !important;
    padding: 0 !important;
    background: transparent !important;
  }
  .profileSideMenu {
    background: #0d0d30;
    border-radius: 0;
    padding: 15px;
    position: fixed;
    top: 0;
    opacity: 0;
    z-index: 99999;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.25s;
  }
  .profileSideMenu.collapse.show::before {
    background: rgba(5, 5, 31, 0.75);
    content: "";
    left: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    transition: 0.25s;
    width: 100%;
    z-index: -1;
  }
  .light_theme .profileSideMenu.collapse.show::before {
    background: rgb(233 233 233 / 80%);
  }
  .light_theme .profileSideMenu {
    box-shadow: 0 0 8px 4px rgb(0 0 0 / 30%);
  }
  .light_theme #mobileMenuDrop a:hover,
  .light_theme #mobileMenuDrop a.active {
    background: #008ccc;
    color: #fff;
  }
  .light_theme #mobileMenuDrop ul li a {
    background: #d7d7d7;
    color: #333;
  }
  .light_theme #mobileMenuDrop a:hover span,
  .light_theme #mobileMenuDrop a.active span {
    filter: unset;
  }
  .profileSideMenu {
    left: -218px;
  }
  .profileSideMenuBottom ul.navbar-nav.topProfileDropdown {
    margin-top: 15px;
  }
  .profileSideMenu.collapse.show {
    left: 0;
    opacity: 1;
  }
  .profileSideMenuTop {
    position: relative;
  }
  .profileSideMenuBottom {
    position: relative;
  }
  .visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .hamburger {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
  }
  .hamburger .bar {
    padding: 0;
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute;
  }
  .bar1 {
    top: 0;
  }
  .bar2,
  .bar3 {
    top: 12.5px;
  }
  .bar3 {
    right: 0;
  }
  .bar4 {
    bottom: 0;
  }
  .hamburger2 .bar2,
  .hamburger3 .bar3,
  .hamburger4 .bar4 {
    top: 12.5px;
  }
  .hamburger4 .bar5 {
    bottom: 2px;
  }
  .hamburger4 .bar {
    transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
  }
  .hamburger4 .bar2 {
    width: 1px;
    transform: rotate(90deg);
    left: 12.5px;
  }
  .hamburger4 .bar3 {
    width: 1px;
    left: 12.5px;
  }
  .profileSideMenuBottom .navbar-toggler {
    position: absolute;
    top: -100px;
    left: 130px;
  }
  .profileSideMenuBottom .hamburger4 > .bar1 {
    top: 12.5px;
    background-color: transparent;
  }
  .profileSideMenuBottom .hamburger4 > .bar2 {
    left: 0px;
    width: 30px;
    transform: rotate(45deg);
  }
  .profileSideMenuBottom .hamburger4 > .bar3 {
    left: 0;
    width: 30px;
    transform: rotate(-45deg);
  }
  .profileSideMenuBottom .hamburger4 > .bar4 {
    background-color: transparent;
  }
  .profileSideMenuBottom .hamburger4 > .bar5 {
    bottom: 12.5px;
    background-color: transparent;
  }
  /* .walletSideMenu {
    position: fixed;
    width: 100%;
    z-index: 9;
    bottom: 0;
    background: #07071a;
    left: 0;
    padding: 15px;
  }
  .walletSideMenu .walletSideMenuBottom .navbar-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: center;
  }
  .walletSideMenu .walletSideMenuBottom .navbar-nav .nav-item a{
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .walletSideMenu .walletSideMenuBottom .navbar-nav .nav-item a span{
    display: block;
    margin-bottom: 7px;
    margin-right: 0;
  }
  .mobile-bottom-space {
    margin-bottom: 130px;
  } */
  nav.trade_menu ul.topProfileDropdown {
    display: block;
  }
  .trade_sidemenu .profileSideMenuBottom .navbar-toggler {
    top: -5px;
  }
  .trade_sidemenu li.nav-item {
    padding: 5px 0 !important;
  }
  .topProfileDropdown_mobile {
    display: flex !important;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .trade_sidemenu li.nav-item img {
    margin-right: 12px;
    filter: brightness(0) invert(1);
  }
  .light_theme .trade_sidemenu li.nav-item img {
    filter: brightness(1) invert(1) contrast(0);
  }
  .demo_list label {
    padding: 8px 0;
  }
  .demo_list label:hover {
    background: transparent !important;
  }
  .trade_main_left_top {
    flex-wrap: wrap;
  }
  .coin_details {
    min-width: 100%;
  }
  .trade_chart {
    margin-top: 10px;
  }
  nav.trade_menu ul.topProfileDropdown li:nth-child(1) {
    padding-left: 0;
  }
  .batch_settings_modal_panel {
    flex-wrap: wrap;
  }
  .batch_settings_modal_panel .coin_details {
    min-width: 100%;
    margin-right: 0;
  }
  .batch_settings_modal_right {
    width: 100%;
    margin-top: 30px;
  }
  #batch_setting_modal .modal-dialog {
    min-width: inherit;
  }
  .walletSideMenu {
    margin-bottom: 15px;
  }
  .menu_round_btn {
    background: #008ccc;
  }
  .trade_chart {
    bottom: 0;
    overflow: auto;
    position: fixed;
    right: -100%;
    top: 0;
    transition: right 0.5s ease;
    width: 100%;
    z-index: 2200;
    background: #111;
    padding: 10px;
    margin-top: 0;
  }
  .trade_chart.show {
    right: 0;
  }
  body.filter_overlay {
    position: relative;
    overflow: hidden;
  }
  .chart_close {
    font-size: 24px;
  }
  body.filter_overlay::after {
    content: "";
    background-color: #000;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1500;
  }
  .chart_mobile_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .chart_mobile_head h5 {
    margin: 0;
    font-weight: 600;
  }
  div#tv_chart_container {
    height: 92vh;
  }
  .light_theme .trade_chart {
    background: #f3f3f3;
  }
  .light_theme .chart_mobile_head {
    color: #000;
  }
}
@media (max-width: 767px) {
  .api_binding_tab .nav-item .nav-link {
    min-height: 140px;
    padding: 20px;
  }
  .api_binding_tab {
    gap: 5px;
  }
  .api_binding_card {
    padding: 15px;
  }
  .flexTitle {
    display: block;
  }
  .chartTitle {
    display: block;
  }
  .flexTitle .dashboardTabs {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .dashboardTabs li {
    max-width: unset;
  }
  .btn.btn-green-big {
    font-size: 16px;
    min-width: auto;
    padding: 5px 30px;
  }
  .form-card {
    padding: 0 15px 15px 15px;
  }
  body {
    font-size: 16px;
  }
  .referral_panel {
    padding: 50px 15px;
  }
  .referral_panel .input-group {
    max-width: 80%;
  }
  .referral_panel h3 {
    font-size: 24px;
  }
  .share_modal .modal-dialog {
    max-width: 95%;
    margin: 0 auto;
  }
  .dashboardTwoColumn .row:first-child > .col-md-6:nth-child(2) {
    margin-top: 30px;
  }
  #share_page_modal {
    width: 90%;
  }
  .profileTitleFlex {
    display: flex !important;
  }
  .info_alert p {
    max-width: 80%;
  }
}
@media (max-width: 579px) {
  .trade_topbar > div:first-child,
  .trade_topbar > div,
  .trade_topbar > div:last-child {
    padding: 12px;
  }
  .trade_topbar > div {
    width: 50%;
  }
  .batch_settings_btn_grp button {
    font-size: 16px;
  }
  .batch_settings_btn_grp button img {
    display: none;
  }
  .new_bot_panel > a {
    padding: 10px 15px;
  }
  nav.trade_menu ul.topProfileDropdown li {
    padding: 0 10px 0 0;
  }
  .api_binding_tab .nav-item .nav-link {
    min-height: 100px;
    padding: 10px;
  }
  .api_binding_card_title {
    display: block;
  }
  .referral_panel .input-group {
    max-width: 100%;
  }
  .referral_panel h4 {
    margin: 0 0 15px;
    display: block;
  }
  .share_modal .modal-body .share_modal_body_top {
    display: block;
  }
  .share_modal .modal-body .share_modal_body_left,
  .share_modal .modal-body .share_modal_body_right {
    width: 100%;
  }
  .share_modal_body_right img {
    width: 150px;
    margin-bottom: 30px;
  }
  .social_share_list li {
    margin-right: 10px;
  }
  .revenue_flex {
    justify-content: flex-start;
  }
  .pair_table_bodyrow_top > div:first-child h3 {
    font-size: 13px;
  }
  .pair_table_bodyrow_top > div:first-child h3 span {
    font-size: 11px;
  }
  .pair_table_bodyrow_top img {
    width: 20px;
  }
  .per_change {
    font-size: 12px;
  }
  #pairTab {
    margin-bottom: 15px !important;
  }
  .member_box {
    flex-wrap: wrap;
  }
  .member_box > div:first-child {
    width: 100% !important;
  }
  .member_box > div {
    width: 50%;
    margin-bottom: 15px;
  }
  .wbCard {
    margin-bottom: 30px;
  }
  .flexTitle .filterSelect {
    margin-top: 15px;
    width: 100%;
    max-width: 100% !important;
  }
  .userContent {
    padding: 10px;
  }
  .auth_otp_btn {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
  }
  .auth_otp_btn button.input-group-text {
    width: 100%;
  }
  .pair_table_bodyrow_bottom label {
    font-size: 12px;
  }
  .batch_settings_modal_panel .coin_details .form-check-input[type="checkbox"] {
    margin-right: 6px;
    margin-top: 14px;
    width: 12px;
    height: 12px;
  }
  .batch_settings_modal_panel .coin_details .pair_table_bodyrow {
    padding-right: 0;
  }
  .revenue_flex {
    display: block;
  }
  .revenue_flex h4:nth-child(1) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
@media (max-width: 401px) {
  .navbar-brand img {
    width: 190px;
  }
  .form-control,
  .input-group-text {
    font-size: 14px;
  }
  .input-group-text {
    padding: 5px;
  }
}
@media (max-width: 340px) {
  .api_binding_tab .nav-item .nav-link {
    min-height: 80px;
    padding: 10px;
  }
  .share_modal_body_right img {
    width: 120px;
  }
  .navbar-brand img {
    width: 150px;
  }
  .form-card {
    padding: 0 5px 15px 5px;
  }
  .input-group-text {
    padding: 3px;
  }
}
